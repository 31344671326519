import Countdown from "react-countdown";
import { FaInfoCircle } from "react-icons/fa";

import { useFetch } from "../../../hooks/useFetch";
import { useGame } from "../../../hooks/useGame";

export const PlayersQueueCounter = () => {
  const { countRankedGames } = useFetch();
  const { queueDetails } = useGame();

  // EVERY DAY AT 00:00:00 UTC RANKING WILL BE RESET
  const timeToResetRanking = new Date().setUTCHours(0, 0, 0, 0) + 86400000;

  const rankedGamesPlayedToday = countRankedGames?.gamesCount ?? 0;

  const rankedGamesPlayedOnTheMonth = countRankedGames?.monthlyGameCount ?? 0;

  return (
    <div className="flex gap-2">
      <div className="flex flex-col-reverse gap-3">
        <small className="flex items-center gap-3">
          <span>Players in the queue: {queueDetails?.length || 0}</span>
          {queueDetails?.length > 0 && (
            <div className="tooltip !relative">
              <span className="button !bottom-[30px] !left-[-87px]">
                {queueDetails.slice(0, 5).map((e, _) => (
                  <>
                    <div key={Number(e.id) + "@" + Number(_)}>
                      {e.player.player}
                      {queueDetails.length > 5 ? "..." : null}
                    </div>
                    {queueDetails.length > 5 ? <br /> : null}
                  </>
                ))}
              </span>
              <div>
                <FaInfoCircle />
              </div>
            </div>
          )}
        </small>

        <div className="flex flex-col gap-1">
          <small className="">
            Daily matches reset in:{" "}
            <Countdown
              date={timeToResetRanking}
              renderer={({ hours, minutes, seconds }) => (
                <b>
                  {hours}h {minutes}m {seconds}s
                </b>
              )}
            />
          </small>
          <small className="w-[250px]">
            Ranked matches today: <b>{rankedGamesPlayedToday}/20</b>
          </small>
          <small className="w-[250px] whitespace-nowrap">
            Ranked matches this month: <b>{rankedGamesPlayedOnTheMonth}/500</b>
          </small>
        </div>
      </div>
    </div>
  );
};
